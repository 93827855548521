import React, { useState } from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import SportsMotorsportsOutlinedIcon from '@mui/icons-material/SportsMotorsportsOutlined';

import './popUpRetornosMotorista.scss'

import Button, { CopyButton } from '../button/Button';
import PopUp from './PopUpVazio';
import PopUpConfirmaRetorno from './PopUpVazio'
import PopUpConfirmaRetornoTodos from './PopUpVazio'
import PopUpAlerta from '../popUpAlerta/PopUpAlerta'
import { atualizaStatusRetorno } from '../../api/retornos';


const PopUpRetornosMotorista = ({ trigger, setTrigger, retornos, atualizaRetornos, atualiza }) => {
  const [popUpConfirmaRetorno, setPopUpConfirmaRetorno] = useState(false)
  const [popUpConfirmaRetornoTodos, setPopUpConfirmaRetornoTodos] = useState(false)

  const [conteudoPopUp, setConteudoPopUp] = useState("");
  const [popUpAlerta, setPopUpAlerta] = useState(false);
  const [retornouId, setRetornouId] = useState()

  const idEmpresa = JSON.parse(localStorage.getItem("usuarioCash"))[0]["id"]

  const handleRetornou = async (id) => {
    try {
      await atualizaStatusRetorno(id, "restaurante", "Retornou", {historico:[{data: new Date(), status: "Retornou", operador: idEmpresa }]});
      await atualizaRetornos();
      setPopUpConfirmaRetorno(false);
      setTrigger(false);
      atualiza(true)
    } catch (error) {
      setPopUpConfirmaRetorno(false);
      setTrigger(false);
      setConteudoPopUp(`Erro ao lidar com retorno de ID ${id}. Tente novamente.`);
      setPopUpAlerta(true);
      console.error("Erro ao lidar com retorno:", error);
    }
  }

  const handleRetornouTodos = () => {
    try{
      retornos.forEach(element => {
        handleRetornou(element.retorno._id)
        setPopUpConfirmaRetornoTodos(false)
        setTrigger(false)  
      });

      atualiza(true)
    }
    catch(error){
      setPopUpConfirmaRetornoTodos(false)
      setTrigger(false)
      setConteudoPopUp("Erro ao lidar com retornos do motorista. Tente novamente. ");
      setPopUpAlerta(true);
      console.error("Erro ao lidar com retornos do motorista:", error);
    }
  }

  return (
    trigger ?
      <PopUp trigger={trigger} setTrigger={setTrigger} style={{maxWidth: '1300px'}}>
        <div className='popUpConteudo retornosMotorista' style={{maxHeight: "70vh" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <SportsMotorsportsOutlinedIcon fontSize="medium" style={{ marginRight: "5px", marginLeft: "15px" }} />
            <h4>{retornos[0].retorno.motorista}</h4>
            {retornos[0].retorno.status === "Aguardando"? 
              < Button
                text='Retornou Todas'
                width='130px'
                borderRadius='8px'
                border='black'
                background='#6B4380'
                color='white'
                margin="0px 20px 0px auto"
                onClick={() => {setPopUpConfirmaRetornoTodos(true)}}
              />
              : null
            }
          </div>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow className='table-title'>
                  <TableCell><b>Nº OS</b></TableCell>
                  <TableCell><b>Nº Pedido</b></TableCell>
                  <TableCell><b>Nº Controle</b></TableCell>
                  <TableCell><b>Parada</b></TableCell>
                  <TableCell align='center'><b>Data Solicitação</b></TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {retornos.map((retorno, index) => (
                  <LinhaTabela
                    key={index}
                    setPopUpConfirmaRetorno={setPopUpConfirmaRetorno}
                    retorno={retorno.retorno}
                    setRetornouId={setRetornouId}
                  />
                ))}
              </TableBody>

            </Table>
          </TableContainer>

        </div>
        <PopUpAlerta trigger={popUpAlerta} setTrigger={setPopUpAlerta}>
          {
            <div>
              {conteudoPopUp}
            </div>
          }
        </PopUpAlerta>

        <PopUpConfirmaRetorno trigger={popUpConfirmaRetorno} setTrigger={setPopUpConfirmaRetorno} light={true} >
          {
            <div style={{ padding: "15px" }}>
              <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                {"Você deseja confirmar esse retorno?"}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                < Button
                  text='Sim'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='rgb(66, 175, 60)'
                  color='white'
                  onClick={() => { handleRetornou(retornouId) }}
                />
                < Button
                  text='Não'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='#c84333'
                  color='white'
                  onClick={() => { setPopUpConfirmaRetorno(false) }}
                />
              </div>

            </div>
          }
        </PopUpConfirmaRetorno>

        <PopUpConfirmaRetornoTodos trigger={popUpConfirmaRetornoTodos} setTrigger={setPopUpConfirmaRetornoTodos} light={true} >
          {
            <div style={{ padding: "15px" }}>
              <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                {"Você deseja confirmar TODOS os retornos desse motorista?"}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                < Button
                  text='Sim'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='rgb(66, 175, 60)'
                  color='white'
                  onClick={ handleRetornouTodos }
                />
                < Button
                  text='Não'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='#c84333'
                  color='white'
                  onClick={() => { setPopUpConfirmaRetornoTodos(false) }}
                />
              </div>

            </div>
          }
        </PopUpConfirmaRetornoTodos>
      </PopUp>
      : null
  );
};

const LinhaTabela = ({ retorno, setPopUpConfirmaRetorno, setRetornouId }) => {

  const handleConfirmaRetorno = async () => {
    setRetornouId(retorno._id)
    setPopUpConfirmaRetorno(true)
  }

  return (<>
    <TableRow key={retorno._id}>
      <TableCell>{retorno.os} <CopyButton link={retorno.os} /></TableCell>
      <TableCell>{retorno.numero_pedido || "-"}</TableCell>
      <TableCell>{retorno.numeroControle || "-"}</TableCell>
      <TableCell>{retorno.parada}</TableCell>
      <TableCell align='center'>
        {retorno.data_solicitacao ? 
        <>
          {
          new Date(retorno.data_solicitacao).toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })} {new Date(retorno.data_solicitacao).toLocaleTimeString('pt-BR')}
        </>
        : "-"
      }
      </TableCell>

      <TableCell>
        {retorno.retorno.dinheiro ?
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LocalAtmOutlinedIcon style={{ marginRight: '5px' }} /> R${retorno.retorno.valorDinheiro}
          </div>
          : null}
        {retorno.retorno.maquininha ?
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CreditCardOutlinedIcon style={{ marginRight: '5px' }} /> R${retorno.retorno.valorMaquininha} ID: {retorno.retorno.idMaquininha}
          </div>
          : null}
        {retorno.retorno.comandaAssinada ?
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ReceiptLongOutlinedIcon style={{ marginRight: '5px' }} />
          </div>
          : null}

      </TableCell>
      <TableCell>
        {retorno.status === "Aguardando" ?
          < Button
            text='Retornou'
            width='120px'
            borderRadius='8px'
            border='black'
            background='rgb(66, 175, 60)'
            color='white'
            onClick={() => { handleConfirmaRetorno() }}
          />
          : null}
      </TableCell>

    </TableRow>
  </>);
}


export default PopUpRetornosMotorista;