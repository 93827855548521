import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {setLogger, useQuery, useMutation, useQueryClient } from 'react-query'
import { Tabs, Tab, Checkbox, FormGroup, FormControlLabel, Badge,  Box, Typography  } from '@mui/material';
import { AccessTime, AlarmOff, Edit, CheckCircle, BorderLeft, BorderRight } from '@mui/icons-material';
import { TableCell, TableRow } from '@mui/material';

import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import ReplyAllOutlinedIcon from '@mui/icons-material/ReplyAllOutlined';
import DoneAll from '@mui/icons-material/DoneAll'
import List from '@mui/icons-material/List'
import DisabledByDefault from '@mui/icons-material/DisabledByDefault'
import CachedIcon from '@mui/icons-material/Cached';
import ShoppingBag from '@mui/icons-material/ShoppingBag'
import ReportIcon from '@mui/icons-material/Report';
import { Icon } from '@mui/material';

import Layout from '../../componentes/layout';
import TabPanel from '../../componentes/tabPanel/TabPanel';
import Input from '../../componentes/input/Input';
import PopUpRetornosMotorista from '../../componentes/popUpVazio/PopUpRetornosMotorista';
import PopUpSolicitaRetorno from '../../componentes/popUp/PopUpSolicitaRetorno';
import PopUpProcuraRetornos from '../../componentes/popUp/PopUpProcuraRetornos';
import PopUpAlerta from '../../componentes/popUp/PopUp'
import PopUpConfirmacao from '../../componentes/popUpVazio/PopUpVazio';
import { AddButton, WorldSearchButton, Button, PhoneButton, CopyButton } from '../../componentes/button/Button'
import Select from '../../componentes/select/Select'
import Botao from '../../componentes/button/Button'

import { getRetornosDaEmpresa, atulizaRetornoMachine, atualizaStatusRetorno, enviaMsgUrgenteMotorista } from '../../api/retornos';
import { getSolicitacaoByID, getSolicitacaoByEmpresa, consultaEmpresa, getRetornosFiltro } from '../../api/retornos'
import "./retornos.scss"

const Retornos = (props) => {

  const [value, setValue] = useState(0);
  const [os, setOs] = useState('')
  const [solicitacaoRetorno, setSolicitacaoRetorno] = useState([])
  const [solicitacoesPesquisa, setSolicitacoesPesquisa] = useState([])
  const [retornosAbertos, setRetornosAbertos] = useState([]);
  const [retornosRetornados, setRetornosRetornados] = useState([]);
  const [retornosModificados, setRetornosModificados] = useState([]);
  const [retornosAtrasados, setRetornosAtrasados] = useState([]);
  const [popUpTrigger, setPopUpTrigger] = useState(false)
  const [conteudoPopUp, setConteudoPopUp] = useState("");
  const [popUpAlerta, setPopUpAlerta] = useState(false);
  const [popUpConfirmacao, setPopUpConfirmacao] = useState(false);
  const [atualiza, setAtualiza] = useState(false);

  const [TamAbertos, setTamAbertos] = useState(0);
  const [TamRetornados, setTamRetornados] = useState(0);
  const [TamModificados, setTamModificados] = useState(0);
  const [TamAtrasados, setTamAtrasados] = useState(0);
  const [idPagina, setIdPagina] = useState(null);


  const [selectLojas, setSelectLojas] = useState([]);
  const [popUpProcuraRetornos, setPopUpProcuraRetornos] = useState(false);


  const idEmpresa = JSON.parse(localStorage.getItem("usuarioCash"))[0]["id"]

  const [mostrarHoje, setMostrarHoje] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [pageRetornados, setPageRetornados] = React.useState(0);
  const [rowsPerPageRetornados, setRowsPerPageRetornados] = React.useState(10);

  const [popUpMotorista, setPopUpMotorista] = useState(false);
  const [conteudoPopUpMotorista, setConteudoPopUpMotorista] = useState("");

  const [selectedOption, setSelectedOption] = useState(null);

  const [inputFilter, setInputFilter] = useState('');
  const [selectFilter, setSelectFilter] = useState('');


  const opcoesSeletor = [
    { value: 'os', label: 'OS' },
    { value: 'motorista', label: 'Nome do Motorista' },
    { value: 'nomeEmpresa', label: 'Nome da Empresa' },
    { value: 'valor', label: 'Valor' },
    { value: 'numero_pedido', label: 'Nº Pedido' }]

  const [state, setState] = useState({
    maquininha: false,
    dinheiro: false,
    comandaAssinada: false,
  });

  const location = useLocation();
  const ativaMenu = location.state?.ativaMenu;

  const empresasFiliais = useQuery([{idEmpresa:idEmpresa}], consultaEmpresa, {refetchInterval: false});


  const handleSubmitFiltro = (event) =>{
    // filtra dados de entrada do filtro simples

    const filter = [selectFilter, inputFilter]

    if(selectFilter=='' ||  inputFilter==''){
      alert("Informe um texto ou selecione um filtro.")
      return
    }


    else if(selectFilter == "valor"){
      if(Number.isNaN(Number(inputFilter))){
        alert("Substua a virgula (,) por ponto (.). Ou verifique se há algum caracter.")
        return
      }else{
        var novoFiltro = [selectFilter, inputFilter]
        filtraRetornos(idPagina,mostrarHoje, setRetornosAbertos, setRetornosRetornados, setRetornosModificados, setRetornosAtrasados, setTamAbertos, setTamAtrasados, setTamModificados, setTamRetornados, novoFiltro)
      }
    }
    else{
     filtraRetornos(idPagina,mostrarHoje, setRetornosAbertos, setRetornosRetornados, setRetornosModificados, setRetornosAtrasados, setTamAbertos, setTamAtrasados, setTamModificados, setTamRetornados, filter)    
  }
}

  const handleClick = (option, obj) => {
    var id = obj.id
    setSelectedOption(option);
    setIdPagina(id)
    fetchData(id);

    async function fetchData(id) {
      try {
        await atualizaRetornos(id, mostrarHoje, setRetornosAbertos, setRetornosRetornados, setRetornosModificados, setRetornosAtrasados, setTamAbertos, setTamAtrasados, setTamModificados, setTamRetornados);
      } catch (error) {
        console.error("Erro ao atualizar retornos no useEffect: ", error);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageRetornados = (event, newPage) => {
    setPageRetornados(newPage)
  }

  const handleChangeRowsPerPage = (
    event
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStateChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const onAddRetorno = async () => {
    try {
      const solicitacao = await fetchSolicitacaoByID(os);
      setSolicitacaoRetorno(solicitacao)

      if (solicitacao && solicitacao.paradas && solicitacao.paradas.length > 0) {
        setPopUpTrigger(!popUpTrigger);
      } else {
        setConteudoPopUp("OS não identificado");
        setPopUpAlerta(true);
      }
    } catch (error) {
      console.error("Erro ao adicionar retorno: ", error);
    }
  };

  const onSearchRetornos = async () => {
    try {
      const solicitacoes = await fetchSolicitacaoByEmpresa();


      const updatedSolicitacoes = solicitacoes.map((solicitacao) => {
        const updatedParadas = solicitacao.paradas.filter((parada) => {
          return !Object.values(retornosAbertos).some((retornos) =>
            retornos.some((retorno) => retorno?.retorno?.numero_pedido === parada?.numero_pedido)
          );
        });

        if (updatedParadas.length === 0) return null;
        return { ...solicitacao, paradas: updatedParadas };
      }).filter(Boolean);

      setSolicitacoesPesquisa(updatedSolicitacoes);

      if (updatedSolicitacoes.length > 0) {
        setPopUpProcuraRetornos(!popUpProcuraRetornos);
      } else {
        setConteudoPopUp("Não foram encontrados novos retornos!");
        setPopUpAlerta(true);
      }
    } catch (error) {
      console.error("Erro ao adicionar retorno: ", error);
    }
  };

  const fetchSolicitacaoByEmpresa = async () => {
    try {
      const response = await getSolicitacaoByEmpresa(idPagina);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar solicitacao pela OS: ', error);
    }
  }

  const consultaEmpresaBanco = async (dados) => {
    try {
      const response = await consultaEmpresa(dados);
      return response.data;
    } catch (error) {
      console.error('Erro ao consutar dados ', error);
    }
  }

  const fetchSolicitacaoByID = async (id) => {
    try {
      const response = await getSolicitacaoByID(id);
      return response.data.response[0];
    } catch (error) {
      console.error('Erro ao buscar solicitacao pela OS: ', error);
    }
  };

  const atualizaRetornosFromMachine = async () => {
    try {
      const novosRetornosAbertos = await Promise.all(
        Object.values(retornosAbertos).map((retornosMotorista, index) => {
          retornosMotorista.filter(retorno =>
            !retorno.retorno.finalizado ||
            !retorno.retorno.motorista ||
            !retorno.retorno.telefone_motorista ||
            !retorno.retorno.nomeEmpresa||
            !retorno.retorno.idMotorista
          )
            .map(async (retorno) => {
              const novoRetorno = await atulizaRetornoMachine(retorno.retorno._id, retorno.retorno.os);
            })
        }
        ));
      setRetornosAbertos([])
    } catch (error) {
      console.error(`Erro ao atualizar retornos em aberto: ${error} `);
    }

    try {
      const novosRetornosRetornados = await Promise.all(
        retornosRetornados.filter(retorno =>
          !retorno.finalizado ||
          !retorno.motorista ||
          !retorno.telefone_motorista ||
          !retorno.nomeEmpresa
        )
          .map(async (retorno) => {
            const novoRetorno = await atulizaRetornoMachine(retorno._id, retorno.os);
          })
      );
      setRetornosRetornados([])
    } catch (error) {
      console.error(`Erro ao atualizar retornos em retornados: ${error} `);
    }

    try {
      atualizaRetornos(idEmpresa, mostrarHoje, setRetornosAbertos, setRetornosRetornados, setRetornosModificados, setRetornosAtrasados)
    } catch (error) {
      console.error(`Erro ao atualizar retornos nas variáveis `, error)
    }
  };

  function selecionaMotoristasRetornoUrgente() {
    const motoristasNotificados = new Set();
  
    for (const motorista in retornosAbertos) {
      if (retornosAbertos.hasOwnProperty(motorista)) {
        const retornos = retornosAbertos[motorista];
  
        for (const retorno of retornos) {
          if (!motoristasNotificados.has(motorista)) {
            if (
              (state.comandaAssinada && retorno.retorno.retorno.comandaAssinada) ||
              (state.dinheiro && retorno.retorno.retorno.dinheiro) ||
              (state.maquininha && retorno.retorno.retorno.maquininha)
            ) {
              motoristasNotificados.add(motorista);
              enviaMsgUrgenteMotorista(retorno.retorno);
            }
          }
        }
      }
    }

    setState({
      maquininha: false,
      dinheiro: false,
      comandaAssinada: false
    });

    setPopUpConfirmacao(false);

  }

  const styles = {
    sidebar: {
      width: "200px",
      maxHeight:'790px',
      height: "100vh",
      backgroundColor: "#f5f5f5",
      padding: "10px",
      boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
    },
    title: {
      fontSize: "18px",
      color: "#333",
      marginBottom: "20px",
      textAlign: "center",
    },
    menu: {
      listStyleType: "none",
      padding: 0,
    },
    menuItem: {
      padding: "10px 15px",
      cursor: "pointer",
      borderRadius: "5px",
      transition: "background-color 0.3s",
    },
  };


  useEffect(() => {

    if(atualiza === true){
      setAtualiza(false)
    }

    if(empresasFiliais.status !== 'success'){
      return
    }
    if(ativaMenu !== true){
      setIdPagina(idEmpresa)
      fetchData(idEmpresa);

      return
    }

    if(empresasFiliais.data.data.length < 1){
      setIdPagina(idEmpresa)
      fetchData(idEmpresa);

    }else{
      var id = empresasFiliais.data.data[0].idsEmpresas[0].id
      setSelectLojas(empresasFiliais.data.data[0].idsEmpresas)
      setSelectedOption(empresasFiliais.data.data[0].idsEmpresas[0].nome);
      setIdPagina(id)
      fetchData(id);
    }

    async function fetchData(id) {
      try {
        await atualizaRetornos(id, mostrarHoje, setRetornosAbertos, setRetornosRetornados, setRetornosModificados, setRetornosAtrasados, setTamAbertos, setTamAtrasados, setTamModificados, setTamRetornados);
      } catch (error) {
        console.error("Erro ao atualizar retornos no useEffect: ", error);
      }
    }
    
  }, [empresasFiliais.isSuccess, atualiza, setAtualiza]);

  
  
  return (
    <div className="main">
      {  ativaMenu === true && selectLojas.length > 0  &&
        <div className='menu-lateral-empresas'> 
        <div style={styles.sidebar}>
          <ul style={styles.menu}>
            {selectLojas.map((option, index) => (
              <li
                key={index}
                style={{
                  ...styles.menuItem,
                  backgroundColor: selectedOption === option.nome ? "#6B4380" : "transparent",
                  color: selectedOption === option.nome ? "white" : "#333",
                  fontSize:'15px'
                }}
                onClick={() => handleClick(option.nome, option)}
              >
                <div style={{ display:'flex', justifyContent:'space-between'}} >
                  <div style={{width:'110px',   whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {option.nome}
                  </div>
                  
                  <div>
                    {option?.atrasado &&
                      <Icon style={{color: selectedOption === option.nome ? "white" : "#333", fontSize:'19px'}} className="icon-update">timer</Icon>
                    }
                    {option?.modificado &&
                      <Icon style={{color: selectedOption === option.nome ? "white" : "#333",fontSize:'19px', marginLeft:'3px'}} className="icon-update">campaign</Icon>
                    }
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      }
      {ativaMenu === true && selectLojas.length > 0  &&
        <div style={{border:'1px solid rgb(168, 163, 163)', marginTop:'90px', maxHeight:'800px'}}>
        </div>
      }
      
      <div className="tela-principal-retornos">
        <div className="titulo-grandes-entregas">
          <div className="texto-titulo-Grandes-encomendas">
            <AssignmentReturnIcon style={{ width: '30px', height: 'auto', color: '#6B4380' }} />
            Controle de Retornos
          </div>

          <div className="tabs-container">
            <Tabs value={value} onChange={handleChange} aria-label="Retornos">
            <Tab label={ <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}> <Typography sx={{ marginRight: 2 }} variant="body1">Abertos</Typography> <Badge badgeContent={TamAbertos} color="error" sx={{ marginLeft: 1 }} /></Box>}/>    
            <Tab label={ <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}> <Typography sx={{ marginRight: 2 }} variant="body1">Atrasados</Typography> <Badge badgeContent={TamAtrasados} color="error" sx={{ marginLeft: 1 }} /></Box>}/>              
            <Tab label={ <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}> <Typography sx={{ marginRight: 2 }} variant="body1">Modificados</Typography> <Badge badgeContent={TamModificados} color="error" sx={{ marginLeft: 1 }} /></Box>}/>              
            <Tab label={ <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}> <Typography sx={{ marginRight: 2 }} variant="body1">Retornados</Typography> <Badge badgeContent={TamRetornados} color="error" sx={{ marginLeft: 1 }} /></Box>}/>              
            </Tabs>
          </div>

        </div>

        <div className='abraca-conteudo-interno'>
        <div className='flex-line' style={{ display: 'flex', alignItems: 'center' }}>
          <div className='flex-line' style={{ display: 'flex', alignItems: 'center' }}>
            {value == 0 ?
              <>
                <Input
                  name='os'
                  value={os}
                  onChange={(e) => setOs(e)}
                  placeholder={'Número OS'}
                  className="placeholder"
                  margin="0px"
                  width={"280px"}
                  height={"23px"}
                />
                <AddButton onClick={onAddRetorno} />

                <div style={{ display: 'flex', height: '37px', alignItems: 'center', margin: '0 8px' }}> ou </div>

                <WorldSearchButton onClick={onSearchRetornos} />

              </>
              :
              <div className='center' style={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
                <Checkbox
                  type="checkbox"
                  name="dinheiro"
                  checked={mostrarHoje}
                  onChange={() => setMostrarHoje(!mostrarHoje)}
                />
                Mostrar somente hoje
              </div>
            }
          </div>

          <div className='center' style={{ marginLeft: 'auto' }}>
            <Button className="danger" onClick={()=>setPopUpConfirmacao(true)}>
              <ReportIcon />
            </Button>
          </div>
          </div>
        </div>
        <div className='bloco-input' style={{display:'flex', justifyContent:'flex-end', margin:'5px', marginTop:'30px' }}>
          <Input tipo={"texto"} width={"240px"} height={"25px"} name={"inputFilter"} value={inputFilter} onChange={setInputFilter} placeholder="Digite aqui..." />
          <Select name={"selectFilter"} value={selectFilter} onChange={setSelectFilter} options={opcoesSeletor} titulo={"Filtros"}/>
          <Botao onClick={() => {handleSubmitFiltro()}}   width={"100px"} color={"#ffffff"} background={'#6B4380'} border={'1px solid #6B4380'} text={"Filtrar"}/>
        </div>


        {retornosAbertos.length !== 0 &&
          <div className='abraca-conteudo-interno'>
            <TabPanel
              value={value}
              index={0}
              rowCount={Object.keys(retornosAbertos).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            >

              {Object.values(retornosAbertos)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((retornoMotorista, index) => (
                <LinhaTabela
                  key={index}
                  setAtualiza={setAtualiza}
                  tabela={0}
                  retorno={retornoMotorista}
                  setPopUpMotorista={setPopUpMotorista}
                  setConteudoPopUpMotorista={setConteudoPopUpMotorista}
                />

              ))}
            </TabPanel>
          </div>
        }

        {retornosAtrasados.length !== 0 &&
          <div className='abraca-conteudo-interno'>
            <TabPanel
              value={value}
              index={1}
              rowCount={Object.keys(retornosAtrasados).length}
              rowsPerPage={rowsPerPage}
              page={pageRetornados}
              onPageChange={handleChangePageRetornados}
              onRowsPerPageChange={handleChangeRowsPerPage}
              agrupaPorMotorista={false}
            >
              {retornosAtrasados?.slice(pageRetornados * rowsPerPage, pageRetornados * rowsPerPage + rowsPerPage).map((retorno, index) => (
                <LinhaTabela
                  key={index}
                  setAtualiza={setAtualiza}
                  tabela={1}
                  retorno={retorno}
                  setRetornosAbertos={setRetornosAbertos}
                  setRetornosAtrasados={retornosAtrasados}
                  agrupaPorMotorista={false}
                  atualizaRetornos={()=>atualizaRetornos(idPagina, mostrarHoje, setRetornosAbertos, setRetornosAtrasados, setRetornosModificados, setRetornosAtrasados)}
                />
              ))}
            </TabPanel>
          </div>
        }


        {retornosModificados.length !== 0 &&
          <div className='abraca-conteudo-interno'>
            <TabPanel
              value={value}
              index={2}
              rowCount={Object.keys(retornosModificados).length}
              rowsPerPage={rowsPerPage}
              page={pageRetornados}
              onPageChange={handleChangePageRetornados}
              onRowsPerPageChange={handleChangeRowsPerPage}
              agrupaPorMotorista={false}
            >
              {retornosModificados?.slice(pageRetornados * rowsPerPage, pageRetornados * rowsPerPage + rowsPerPage).map((retorno, index) => (
                <LinhaTabela
                  key={index}
                  setAtualiza={setAtualiza}
                  tabela={2}
                  retorno={retorno}
                  setRetornosAbertos={setRetornosAbertos}
                  setRetornosModificados={retornosModificados}
                  agrupaPorMotorista={false}
                  atualizaRetornos={()=>atualizaRetornos(idPagina, mostrarHoje, setRetornosAbertos, setRetornosAtrasados, setRetornosModificados, setRetornosAtrasados)}
                />
              ))}
            </TabPanel>
          </div>
        }


        {retornosRetornados.length !== 0 &&
          <div className='abraca-conteudo-interno'>
            <TabPanel
              value={value}
              index={3}
              rowCount={Object.keys(retornosRetornados).length}
              rowsPerPage={rowsPerPage}
              page={pageRetornados}
              onPageChange={handleChangePageRetornados}
              onRowsPerPageChange={handleChangeRowsPerPage}
              agrupaPorMotorista={false}
            >
              {retornosRetornados?.slice(pageRetornados * rowsPerPage, pageRetornados * rowsPerPage + rowsPerPage).map((retorno, index) => (
                <LinhaTabela
                  key={index}
                  setAtualiza={setAtualiza}
                  retorno={retorno}
                  tabela={3}
                  setRetornosAbertos={setRetornosAbertos}
                  setRetornosRetornados={setRetornosRetornados}
                  agrupaPorMotorista={false}
                  atualizaRetornos={()=>atualizaRetornos(idPagina, mostrarHoje, setRetornosAbertos, setRetornosRetornados,  setRetornosModificados, setRetornosAtrasados)}
                />
              ))}
            </TabPanel>
          </div>
        }

        <PopUpSolicitaRetorno atualiza={setAtualiza} id={idPagina} os={os} setOs={setOs} trigger={popUpTrigger} setTrigger={setPopUpTrigger} solicitacao={solicitacaoRetorno} atualizaRetornos={() => atualizaRetornos(idPagina, mostrarHoje, setRetornosAbertos, setRetornosRetornados)} />

        <PopUpProcuraRetornos atualiza={setAtualiza} trigger={popUpProcuraRetornos} idPagina={idPagina} setTrigger={setPopUpProcuraRetornos} retornos={solicitacoesPesquisa} atualizaRetornos={() => atualizaRetornos(idPagina, mostrarHoje, setRetornosAbertos, setRetornosRetornados)} />

        <PopUpAlerta trigger={popUpAlerta} setTrigger={setPopUpAlerta}>
          {
            <div>
              {conteudoPopUp}
            </div>
          }
        </PopUpAlerta>

        <PopUpRetornosMotorista
          atualiza={setAtualiza}
          trigger={popUpMotorista}
          setTrigger={setPopUpMotorista}
          retornos={conteudoPopUpMotorista}
          atualizaRetornos={() => atualizaRetornos(idPagina, mostrarHoje, setRetornosAbertos, setRetornosRetornados, setRetornosModificados, setRetornosAtrasados)}
        />

        <PopUpConfirmacao trigger={popUpConfirmacao} setTrigger={setPopUpConfirmacao} light={true}>
           
          {
            <div style={{ padding: "15px" }}>
              <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                {"Enviaremos mensagem para todos os motoristas que tem retorno de seu estabelecimento."}<br />
                {"Por favor, confirme qual tipo de retorno você quer sinalizar:"}
              </div>
              <div style={{width: 'fit-content', margin: 'auto', paddingLeft: "15px" }}>
                <FormGroup row={true}>
                  <FormControlLabel disableTypography={true} control={<Checkbox checked={state.dinheiro} onChange={handleStateChange} name="dinheiro" />} label="Dinheiro" />
                  <FormControlLabel disableTypography={true} control={<Checkbox checked={state.maquininha} onChange={handleStateChange} name="maquininha" />} label="Maquininha" />
                  <FormControlLabel disableTypography={true} control={<Checkbox checked={state.comandaAssinada} onChange={handleStateChange} name="comandaAssinada" />} label="Comanda Assinada" />
                </FormGroup>
              </div>
              <div style={{width: 'fit-content', margin: 'auto', marginTop: '20px'}}>
                < Button
                  text='Sim'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='rgb(66, 175, 60)'
                  color='white'
                  onClick={selecionaMotoristasRetornoUrgente}
                />
                < Button
                  text='Não'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='#c84333'
                  color='white'
                  onClick={() => { setPopUpConfirmacao(false) }}
                />
              </div>

            </div>
          }
        </PopUpConfirmacao>

      </div>
    </div>
  );
};




const LinhaTabela = ({tabela, setAtualiza, retorno, setPopUpMotorista, setConteudoPopUpMotorista, agrupaPorMotorista = true, atualizaRetornos=() => {} }) => {
  const [popUpConfirmacao, setPopUpConfirmacao] = useState(false);
  const [historico, setHitorico] = useState(false);
  const [popUpConfirmaRetorno, setPopUpConfirmaRetorno] = useState(false);
  const [popUprejeitaRetorno, setPopUprejeitaRetorno] = useState(false);
  const [popUpAtradados, setPopUpAtradados] = useState(false);
  const idEmpresa = JSON.parse(localStorage.getItem("usuarioCash"))[0]["id"]


  function handleVisualizaMotorista() {
    setPopUpMotorista(true)
    setConteudoPopUpMotorista(retorno);
  }

  function contaRetornos() {
    return retorno.length;
  }

  function encontraDataMaisAntiga() {
    let indiceMaisAntigo = -1;
    let dataMaisAntiga = new Date('9999-12-31T23:59:59.999Z');
  
    retorno.forEach((item, index) => {
      const dataStr = item.retorno.data_solicitacao;
      if (!dataStr) return;
  
      const dataFormatada = new Date(dataStr.replace(" ", "T")); // Ajusta o formato
  
      if (!isNaN(dataFormatada) && dataFormatada < dataMaisAntiga) {
        dataMaisAntiga = dataFormatada;
        indiceMaisAntigo = index;
      }
    });

    var data =  new Date(retorno[indiceMaisAntigo].retorno.data_solicitacao)
    data.setHours(data.getHours() - 1);
    return data.toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  }

  async function onSetAguardando(id, setPopUpConfirmacao) {
    await atualizaStatusRetorno(id, "restaurante", "Atrasado", {historico:[{data: new Date(), status: 'Atrasado', operador: idEmpresa }]});
    atualizaRetornos();
    setPopUpConfirmacao(false);
    setAtualiza(true)
  }


  async function onSetStatus(id, setPopUp, status) {
    await atualizaStatusRetorno(id, "restaurante", status, {historico:[{data: new Date(), status: status, operador: idEmpresa }]});
    atualizaRetornos();
    setPopUp(false);
    setAtualiza(true)
  }

  const LidarComRetorno = ({ id }) => {
    return (
      <>
        <div>
          <Button onClick={() => setPopUpConfirmaRetorno(true)} border='0px' background='none'>
            <DoneAll />
          </Button>
          <Button onClick={() => setPopUprejeitaRetorno(true)} border='0px' background='none'>
            <DisabledByDefault />
          </Button>
        </div>
        <PopUpConfirmacao trigger={popUpConfirmaRetorno} setTrigger={setPopUpConfirmaRetorno} light={true}>
          {
            <div style={{ padding: "15px" }}>
              <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                {"Você confirma que esse retorno realmente voltou para sua loja?"}
              </div>
              <div>
                < Button
                  text='Sim'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='rgb(66, 175, 60)'
                  color='white'
                  onClick={() => { onSetStatus(id, setPopUpConfirmaRetorno, 'Retornou') }}
                />
                < Button
                  text='Não'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='#c84333'
                  color='white'
                  onClick={() => { setPopUpConfirmaRetorno(false) }}
                />
              </div>

            </div>
          }
        </PopUpConfirmacao>

        <PopUpConfirmacao trigger={popUprejeitaRetorno} setTrigger={setPopUprejeitaRetorno} light={true}>
          {
            <div style={{ padding: "15px" }}>
              <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                {"Você tem certeza que este retorno não voltou para sua loja?"}
              </div>
              <div>
                < Button
                  text='Sim'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='rgb(66, 175, 60)'
                  color='white'
                  onClick={() => { onSetStatus(id, setPopUprejeitaRetorno, 'Atrasado') }}
                />
                < Button
                  text='Não'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='#c84333'
                  color='white'
                  onClick={() => { setPopUprejeitaRetorno(false) }}
                />
              </div>

            </div>
          }
        </PopUpConfirmacao>
      </>
    )
  }

  const HistoricoButton = ({ id }) => {
    return (
      <>
        <Button onClick={() => setHitorico(true)} border='0px' background='none'>
          <List />
        </Button>
        <PopUpConfirmacao trigger={historico} setTrigger={setHitorico} light={true}>
          {
            <div className="p-4 bg-white rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-4" style={{color:'#6B4380', marginBottom:'20px'}}>Histórico</h2>
                <ul className="space-y-4">
                  {id.historico.map((item, index) => (
                    <li key={index} className="p-3 border-l-4 border-blue-500 bg-gray-100 rounded-md" style={{ marginBottom:'15px', padding:'5px', borderBottom: '1px solid #6B4380'}}>
                      <p className="text-gray-600 text-sm">
                        <div style={{display: 'flex', width:'250px', justifyContent:'space-between'}}>
                          <div style={{fontWeight:'bold'}}>
                            {'Data do evento: '}
                          </div>
                          <div style={{fontWeight:'bold'}}>
                            { item.data}
                          </div>
                        </div>
                      </p>
                      <p className="text-gray-800 font-medium">Status: {item.status === 'Aguardando'? 'Aberto' : item.status}</p>
                      <p className="text-gray-500 text-sm">Operador: {item.operador}</p>
                    </li>
                  ))}
                </ul>
            </div>
          }
        </PopUpConfirmacao>
      </>
    )
  }

  const Retornou = ({ id }) => {
    return (
      <>
        <Button onClick={() => setPopUpAtradados(true)} border='0px' background='none'>
          <DoneAll />
        </Button>
        <PopUpConfirmacao trigger={popUpAtradados} setTrigger={setPopUpAtradados} light={true}>
          {
            <div style={{ padding: "15px" }}>
              <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                {"Você confirma que esse retorno realmente voltou para sua loja?"}
              </div>
              <div>
                < Button
                  text='Sim'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='rgb(66, 175, 60)'
                  color='white'
                  onClick={() => { onSetStatus(id, setPopUpAtradados, 'Retornou')}}
                />
                < Button
                  text='Não'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='#c84333'
                  color='white'
                  onClick={() => { setPopUpAtradados(false) }}
                />
              </div>

            </div>
          }
        </PopUpConfirmacao>
      </>
    )
  }


  const DescontoConvenio = ({ id }) => {

    var historico = id.historico
    var verifica 

    const arrayData = historico
    .filter(obj => ['Desconto', 'Desconto Convenio'].includes(obj.status))
    .map(obj => obj.data);

    if(arrayData.length > 0){
      verifica = true
    }
    return (
      <>
        <Button onClick={() => setHitorico(true)} border='0px' background='none'>
          {verifica &&
            <ShoppingBag style={{color:'rgb(66, 175, 60)'}}/>
          }
          {!verifica &&
            '-'
          }
        </Button>
      </>
    )
  }



  const ReabrirRetornoButton = ({ id }) => {
    return (
      <>
        <Button onClick={() => setPopUpConfirmacao(true)} border='0px' background='none'>
          <ReplyAllOutlinedIcon />
        </Button>
        <PopUpConfirmacao trigger={popUpConfirmacao} setTrigger={setPopUpConfirmacao} light={true}>
          {
            <div style={{ padding: "15px" }}>
              <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                {"Você deseja reabrir este retorno?"}
              </div>
              <div>
                < Button
                  text='Sim'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='rgb(66, 175, 60)'
                  color='white'
                  onClick={() => { onSetAguardando(id, setPopUpConfirmacao) }}
                />
                < Button
                  text='Não'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='#c84333'
                  color='white'
                  onClick={() => { setPopUpConfirmacao(false) }}
                />
              </div>

            </div>
          }
        </PopUpConfirmacao>
      </>
    )
  }

  return (<>
    {agrupaPorMotorista ?
      <TableRow key={retorno[0].retorno.motorista}>
        <TableCell className='marieta'>{retorno[0].retorno.motorista}</TableCell>
        <TableCell className='marieta'> <PhoneButton link={retorno[0].retorno.telefone_motorista} /></TableCell>
        <TableCell className='marieta' align='center'>{contaRetornos()}</TableCell>
        <TableCell className='marieta'>{encontraDataMaisAntiga()}</TableCell>
        <TableCell className='marieta'>
          < Button
            text='Visualizar'
            width='120px'
            borderRadius='0px'
            border='black'
            background='green'
            color='white'
            onClick={handleVisualizaMotorista}
          />
        </TableCell>

      </TableRow>
      :
      <TableRow className='table-title'>
        <TableCell>{retorno.os} <CopyButton link={retorno.os} /></TableCell>
        <TableCell className='marieta'>{retorno.numero_pedido} </TableCell>
        <TableCell className='marieta'>{retorno.numeroControle} </TableCell>
        <TableCell className='marieta'>{retorno.parada}</TableCell>
        <TableCell className='marieta'>{retorno.motorista}</TableCell>
        <TableCell className='marieta'> <PhoneButton link={retorno.telefone_motorista} /></TableCell>
        <TableCell className='marieta' align='center'>
          {retorno.data_solicitacao ?
            <>
              {
                new Date(retorno.data_solicitacao).toLocaleDateString('pt-BR', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })} {new Date(retorno.data_solicitacao).toLocaleTimeString('pt-BR')}
            </>
            : "-"
          }
        </TableCell>
        <TableCell className='marieta' align='center'>
          {retorno.hora_retorno ?
            <>{ retorno.hora_retorno}
            </>
            : "-"
          }
        </TableCell>
        <TableCell>
          {retorno.retorno.dinheiro ?
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LocalAtmOutlinedIcon style={{ marginRight: '5px' }} /> R${retorno.retorno.valorDinheiro}
            </div>
            : null}
          {retorno.retorno.maquininha ?
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CreditCardOutlinedIcon style={{ marginRight: '5px' }} /> R${retorno.retorno.valorMaquininha} ID: {retorno.retorno.idMaquininha}
            </div>
            : null}
          {retorno.retorno.comandaAssinada ?
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ReceiptLongOutlinedIcon style={{ marginRight: '5px' }} />
            </div>
            : null}
        </TableCell>
        {tabela === 3 && 
          <TableCell className='marieta'>
            <DescontoConvenio id={retorno} />
          </TableCell>
        }
        <TableCell className='marieta'>
        { tabela === 1 && 
            <Retornou id={retorno._id} />
         }
         { tabela === 2 && 
          <LidarComRetorno id={retorno._id} />
         }
         { tabela === 3 && 
            <ReabrirRetornoButton id={retorno._id} />
         }
        </TableCell>
        <TableCell className='marieta'>
          <HistoricoButton id={retorno} />
        </TableCell>
      </TableRow>
    }
  </>);
}

const PaginaRetornos = () => {

  return <>
    <Layout>
      <Retornos />
    </Layout>

  </>

}

const filtraRetornos = async (idEmpresa, mostrarHoje, setRetornosAbertos, setRetornosRetornados,setRetornosModificados, setRetornosAtrasados, setTamAbertos, setTamAtrasados, setTamModificados, setTamRetornados, filtro) => {
  try {
    const retornos = await getRetornosFiltro({filtro:filtro, id:idEmpresa});
    

    let retornosAbertosFiltrados = retornos?.filter(retorno => retorno.status === 'Aguardando');
    let retornosRetornadosFiltrados = retornos?.filter(retorno => retorno.status === 'Retornou');
    let retornosModificadosFiltrados = retornos?.filter(retorno => retorno.status === 'Modificado');
    let retornosAtrasadosFiltrados = retornos?.filter(retorno => retorno.status === 'Atrasado');

    let tamAbertos = retornosAbertosFiltrados.length
    let tamRetornados = retornosRetornadosFiltrados.length
    let tamModificados = retornosModificadosFiltrados.length
    let tamAtrasados = retornosAtrasadosFiltrados.length

    setTamAbertos(tamAbertos)
    setTamRetornados(tamRetornados)
    setTamModificados(tamModificados)
    setTamAtrasados(tamAtrasados)

    if (mostrarHoje) {
      let today = new Date();
      let todayString = today.toISOString().split('T')[0];
      retornosRetornadosFiltrados = retornosRetornadosFiltrados.filter(retorno =>
        retorno?.hora_retorno?.startsWith(todayString)
      );
    }

    agrupaPorMotorista(retornosAbertosFiltrados, setRetornosAbertos);
    setRetornosRetornados(retornosRetornadosFiltrados);
    setRetornosModificados(retornosModificadosFiltrados)
    setRetornosAtrasados(retornosAtrasadosFiltrados)
  } catch (error) {
    console.error("Erro ao atualizar retornos:", error);
  }
}

const atualizaRetornos = async (idEmpresa, mostrarHoje, setRetornosAbertos, setRetornosRetornados,setRetornosModificados, setRetornosAtrasados, setTamAbertos, setTamAtrasados, setTamModificados, setTamRetornados) => {
  try {
    const retornos = await getRetornosDaEmpresa(idEmpresa);

    let retornosAbertosFiltrados = retornos?.filter(retorno => retorno.status === 'Aguardando');
    let retornosRetornadosFiltrados = retornos?.filter(retorno => retorno.status === 'Retornou');
    let retornosModificadosFiltrados = retornos?.filter(retorno => retorno.status === 'Modificado');
    let retornosAtrasadosFiltrados = retornos?.filter(retorno => retorno.status === 'Atrasado');

    let tamAbertos = retornosAbertosFiltrados.length
    let tamRetornados = retornosRetornadosFiltrados.length
    let tamModificados = retornosModificadosFiltrados.length
    let tamAtrasados = retornosAtrasadosFiltrados.length

    setTamAbertos(tamAbertos)
    setTamRetornados(tamRetornados)
    setTamModificados(tamModificados)
    setTamAtrasados(tamAtrasados)

    if (mostrarHoje) {
      let today = new Date();
      let todayString = today.toISOString().split('T')[0];
      retornosRetornadosFiltrados = retornosRetornadosFiltrados.filter(retorno =>
        retorno?.hora_retorno?.startsWith(todayString)
      );
    }

    agrupaPorMotorista(retornosAbertosFiltrados, setRetornosAbertos);
    setRetornosRetornados(retornosRetornadosFiltrados);
    setRetornosModificados(retornosModificadosFiltrados)
    setRetornosAtrasados(retornosAtrasadosFiltrados)
  } catch (error) {
    console.error("Erro ao atualizar retornos:", error);
  }
}

const agrupaPorMotorista = (list, setList) => {
  const groupedByMotorista = list.reduce((acc, retorno) => {
    if (!acc[retorno.motorista]) {
      acc[retorno.motorista] = [];
    }
    acc[retorno.motorista].push({
      retorno
    });
    return acc;
  }, {});

  setList(groupedByMotorista);
}

export default PaginaRetornos;
