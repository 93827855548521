import { useMutation, useQueryClient } from 'react-query'
import React, { useState, useEffect } from 'react'
import { Oval } from 'react-loader-spinner'
import { Checkbox } from '@mui/material';
import { registraRetorno } from '../../api/retornos'

import Button from '../button/Button';
import CardPedido from '../card/cardPedido'
import PopUp from '../popUpVazio/PopUpVazio';
import PopUpAlerta from './PopUp'
import PopUpConfirmaRetorno from '../popUpVazio/PopUpVazio'


import './popUp.scss';
import './popUpProcuraRetornos.scss'
import Input from '../input/Input';

const PopUpProcuraRetornos = ({ trigger, setTrigger, atualizaRetornos, retornos, idPagina, atualiza }) => {
    const [loader, setLoader] = useState(false);
    const [conteudoPopUp, setConteudoPopUp] = useState("");
    const [popUpAlerta, setPopUpAlerta] = useState(false);
    
    const [popUpConfirmaRetorno, setPopUpConfirmaRetorno] = useState(false)

    const idEmpresa = JSON.parse(localStorage.getItem("usuarioCash"))[0]["id"]

    const [carregando, setcarregando] = useState(false);
    
    const [bufferRetorno, setBufferRetorno] = useState("");
    const [bufferParada, setBufferParada] = useState("");
    const [numeroControle, setNumeroControle] = useState("")
    const [formaPagamento, setFormaPagamento] = useState({
        dinheiro: false,
        maquininha: false,
        comandaAssinada: false,
        idMaquininha: '',
        valorDinheiro: '',
        valorMaquininha: '',
    })


    const queryClient = useQueryClient()
    const id = JSON.parse(localStorage.getItem("usuarioCash"))[0]["id"]

    const onDeliveryRegistrationSucess = (e) => {
        queryClient.invalidateQueries();
        if (e["status"] === 200) {
            alert('Retorno registrado com sucesso!')
        }
    }

    const onDeliveryRegistrationError = (e) => {
        setcarregando(false)
        alert(e.response.data.data)
    }

    const registraNovoRetorno = useMutation(registraRetorno, {
        onSuccess: (e) => {
            onDeliveryRegistrationSucess(e);

        },
        onError: (e) => {
            onDeliveryRegistrationError(e);
        }
    });

    const registraRetornos = async(retorno, parada) => {
        const promises = [];
    
        try {
            if (formaPagamento.comandaAssinada || formaPagamento.dinheiro || formaPagamento.maquininha) {
                const entry = {
                    os: retorno.id,
                    numero_pedido: parada?.numero_pedido,
                    numeroControle: numeroControle,
                    idEmpresa: idPagina,
                    idMotorista: retorno?.taxista_id,
                    motorista: retorno?.nome_taxista,
                    telefone_motorista: retorno?.telefone_taxista,
                    nomeEmpresa: retorno?.nome_passageiro,
                    parada: parada.endereco,
                    retorno: formaPagamento,
                    finalizado: retorno?.data_hora_finalizacao,
                    data_solicitacao: retorno?.data_hora_solicitacao,
                    status: "Aguardando",
                    historico:[{data: new Date(), status: "Aguardando", operador: idEmpresa }]
                };
    
                const mutationPromise = new Promise((resolve, reject) => {
                    registraNovoRetorno.mutate(entry, {
                        onSuccess: resolve,
                        onError: reject
                    });
                });
    
                promises.push(mutationPromise);
    
                await Promise.all(promises);
    
                setBufferRetorno("");
                setBufferParada("");
                setNumeroControle("");
                setFormaPagamento({
                    dinheiro: false,
                    maquininha: false,
                    comandaAssinada: false,
                    idMaquininha: '',
                    valorDinheiro: '',
                    valorMaquininha: '',
                });

                atualiza(true)
            }
        } catch (error) {
            console.error("Erro na função registraRetornos:", error);
        }
    };
    

    const lidarRegistroRetorno = async (retorno, parada) => {
        try {
            setcarregando(true);
            await registraRetornos(retorno, parada);
            setcarregando(false);
            setPopUpConfirmaRetorno(false)
            setTrigger(false);

            await atualizaRetornos();

        } catch (error) {
            console.error("Erro ao lidar com registro do retorno: ", error);
            setLoader(false);
        }
    };

    const handleConfirmaRetorno = (retorno, parada) => {
        setBufferParada(parada);
        setBufferRetorno(retorno);
        setPopUpConfirmaRetorno(true);
    }

    const handleCancela = () => {
        setBufferRetorno("");
        setBufferParada("");
        setFormaPagamento({
            dinheiro: false,
            maquininha: false,
            comandaAssinada: false,
            idMaquininha: '',
            valorDinheiro: '',
            valorMaquininha: '',
        })

        setPopUpConfirmaRetorno(false)
    }

    return (
        trigger ?
            <PopUp trigger={trigger} setTrigger={setTrigger}>
                <div className='popUpConteudo'>

                    {carregando == true &&
                        <div className='loader'>
                            <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={carregando} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
                        </div>
                    }

                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {retornos &&
                            Array.isArray(retornos) &&
                            retornos.map(retorno => (
                                retorno.paradas.map((parada, index) => (
                                    <CardPedido 
                                        key={index}
                                        pedido={parada.numero_pedido || "N/A"}
                                        motorista={retorno.nome_taxista}
                                        endereco={parada.endereco}
                                        onClick={() => handleConfirmaRetorno(retorno, parada)}
                                    />
                                ))
                            ))}
                    </div>
                </div>
                
                <PopUpAlerta trigger={popUpAlerta} setTrigger={setPopUpAlerta}>
                    {
                        <div>
                            {conteudoPopUp}
                        </div>
                    }
                </PopUpAlerta>

                <PopUpConfirmaRetorno trigger={popUpConfirmaRetorno} setTrigger={setPopUpConfirmaRetorno} light={true} >
                    {
                        <div style={{ color: "#5a595c" }}>
                            <div style={{ marginBottom: "10px" }}>
                                <strong>{"PEDIDO "}</strong>
                            </div>
                            <div style={{ width: '330px' }}>
                                <p>Pedido: {(bufferParada.numero_pedido || "N/A")}</p>
                                <p>Motorista: {bufferRetorno.nome_taxista}</p>
                                <p>Endereço: {bufferParada.endereco}</p>
                            </div>
                            <br />
                            <Input
                                name='numeroControle'
                                value={numeroControle}
                                onChange={(e) => setNumeroControle(e)}
                                placeholder={'Número de controle'}
                                className="placeholder"
                            />

                            <Retorno formaPagamento={formaPagamento} setFormaPagamento={setFormaPagamento} />

                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                < Button
                                    text='Adicionar Retorno'
                                    width='160px'
                                    borderRadius='8px'
                                    border='black'
                                    background='rgb(66, 175, 60)'
                                    color='white'
                                    onClick={() => { lidarRegistroRetorno(bufferRetorno, bufferParada) }}
                                />
                                < Button
                                    text='Cancelar'
                                    width='120px'
                                    borderRadius='8px'
                                    border='black'
                                    background='#c84333'
                                    color='white'
                                    onClick={() => { handleCancela() }}
                                />
                            </div>

                        </div>
                    }
                </PopUpConfirmaRetorno>
            </PopUp>
            : null
    );
};


const Retorno = ({ formaPagamento, setFormaPagamento }) => {

    const handleChange = (event, metodo) => {
        const { name, checked, type } = event.target || {};

        if (type === 'checkbox') {
            setFormaPagamento(prevState => ({
                ...prevState,
                [name]: checked
            }));
        } else {
            setFormaPagamento(prevState => ({
                ...prevState,
                [metodo]: event
            }));
        }
    }

    return <>
        <div>
            <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                <strong>{"RETORNO"}</strong>
            </div>
            <div style={{ display: 'flex' }}>
                <label>
                    <Checkbox
                        type="checkbox"
                        name="dinheiro"
                        checked={formaPagamento.dinheiro}
                        onChange={(e) => handleChange(e)}
                    />
                    Dinheiro
                </label>

                {formaPagamento.dinheiro && (
                    <div style={{ marginLeft: 'auto' }}>
                        <label style={{ display: 'flex', marginLeft: '15px' }}>
                            <Input
                                type="number"
                                name="valorDinheiro"
                                value={formaPagamento.valorDinheiro}
                                placeholder="Valor (R$)"
                                onChange={(e) => handleChange(e, 'valorDinheiro')}
                                width={'100px'}
                                height={'20px'}
                            />
                        </label>
                    </div>
                )}
            </div>

            <div style={{ display: 'flex' }}>
                <label>
                    <Checkbox
                        type="checkbox"
                        name="maquininha"
                        checked={formaPagamento.maquininha}
                        onChange={(e) => handleChange(e)}
                    />
                    Maquininha
                </label> <br />
                {formaPagamento.maquininha && (
                    <div style={{ display: 'flex', marginLeft: 'auto' }}>
                        <label style={{ display: 'flex', marginLeft: '15px' }}>
                            <Input
                                type="number"
                                name="idMaquininha"
                                value={formaPagamento.idMaquininha}
                                placeholder="ID Maquininha"
                                onChange={(e) => handleChange(e, 'idMaquininha')}
                                width={'110px'}
                                height={'20px'}
                            />
                        </label>
                        <label style={{ display: 'flex', marginLeft: '15px' }}>
                            <Input
                                type="number"
                                name="valorMaquininha"
                                value={formaPagamento.valorMaquininha}
                                placeholder="Valor (R$)"
                                onChange={(e) => handleChange(e, 'valorMaquininha')}
                                width={'100px'}
                                height={'20px'}
                            />
                        </label>
                    </div>
                )}
            </div>

            <label>
                <Checkbox
                    type="checkbox"
                    name="comandaAssinada"
                    checked={formaPagamento.comandaAssinada}
                    onChange={(e) => handleChange(e)}
                />
                Comanda Assinada
            </label> <br />
        </div>
    </>

}

export default PopUpProcuraRetornos;
